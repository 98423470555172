import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import './index.css'

import Hr from './customHr';
import tandemStore from '../stores/store';

class TicketForm extends Component {
    constructor(props) {
        super(props);
        var price = this.getPrice(tandemStore.priceSchedule, "video");
        this.paymentType = ["Pay-Full", "Pay-Deposit"];
        this.state = {
            ticketNumber: this.props.ticketNumber || 0,
            isDeposit: true,
            giftCard: false,
            couponCode: '',
            payType: this.props.payType,
            couponError: '',
            ticketType: "manual",
            isPhone: false,
            memo: '',
            useGiftCard: false
        }
        this.handleRadioButton(this.props.payType)
    }
    componentDidMount() {
        if (this.props.match.path === "/phone") {
            this.setState({ isPhone: true })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.path === "/phone" && !prevState.isPhone) {
            this.setState({ isPhone: true })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.payType === "giftCard") {
            this.setState({ useGiftCard: true, payType: nextProps.payType, couponCode: nextProps.giftCardCoupon });
        }
    }

    ChangeTicketNumber = (event) => {
        if (event.target.value % 1 === 0) {
            this.setState({ ticketNumber: event.target.value });
            this.props.handleTicketUpdate(event.target.value);
        }
    }

    getPrice(priceSchedule, type) {
        var index = priceSchedule.findIndex(x => x.type == type);
        var price = priceSchedule[index]?.price;
        return price;
    }




    handleRadioButton = async (value) => {
        if (value === 'full') {
            this.setState({
                useGiftCard: false,
                giftCard: false
            })
            this?.props?.handleFullDeposit(false);
        }
        else if (value === 'deposit') {
            this.setState({
                useGiftCard: false,
                giftCard: false
            })
            this?.props?.handleDeposit(this.state.isDeposit);
        }
        else if (value === 'giftCard') {
            // this.props.handleGiftCard('giftCard');
            await this.setState({ useGiftCard: true })
            this.setState({
                giftCard: true,
            })
        }
        else if (value === 'nopay') {
            this.setState({
                useGiftCard: false,
                giftCard: false
            })
            this.props.handleFullDeposit("nopay")
        }
    }

    handleFullRadioButton() {
        this.props.handleFullDeposit(false, value);
    }
    incrementValue(e) {
        e.preventDefault();
        let ticketNumber = Number(this.state.ticketNumber) + 1
        this.setState({ ticketNumber: ticketNumber }, () => {
            this.props.handleTicketUpdate(this.state.ticketNumber, "", "", "INC");

        });

    }

    decrementValue(e) {
        e.preventDefault();
        let ticketNumber = Number(this.state.ticketNumber) - 1;

        if (ticketNumber <= this.props.seatsAvailable) {
            this.props.removeError()
        }

        if (ticketNumber >= 0) {
            this.setState({ ticketNumber: ticketNumber }, () => {
                this.props.handleTicketUpdate(this.state.ticketNumber, "", "", "DEC");
            });
        }
    }

    handleCheckCouponCode = () => {
        let { couponCode } = this.state
        tandemStore.checkCoupon({ couponCode: couponCode }).then(res => {
            if (res.data.statusCode == 404) {
                this.props.handleTicketUpdate(0);
                this.setState({ couponError: res.data.message })
            } else {
                if (res.data.couponDetails.category === "giftcard") {
                    this.props.handleGiftCard('giftCard');
                    this.props.validatedGiftCard(couponCode);
                    this.props.handleTicketUpdate(res.data.couponDetails.tickets, res.data.couponDetails);
                    this.props.reservationComponentRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }    
                else if(res.data.couponDetails.category === "groupon") {
                    const newTabUrl = `/groupon?couponCode=${couponCode}&status=${res.data.couponDetails.status}`;
                    window.open(newTabUrl);
                } 
                else {
                    res.data.couponDetails.tickets = this.state.ticketNumber || 1;
                    this.props.handleTandemCoupon(res.data.couponDetails.price, couponCode)
                    this.props.handleTicketUpdate(res.data.couponDetails.tickets, res.data.couponDetails, res.data.discountedTandem);
                    this.props.reservationComponentRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        }).catch(err => {
            console.log(err)
        })

    }

    handleMemoChange = (e) => {
        this.setState({ memo: e.target.value })
    }
    submitMemo = () => {
        let { memo } = this.state;
        this.props.reservationComponentRef.scrollIntoView({ block: 'start', behavior: 'smooth' });
        this.props.addMemoMessage(memo)
    }
    validateTicketNumbers = () => {
        this.props.validateTickets(this.props.seatsAvailable)
    }

    render() {
        let { params } = this.props.match;
        const { giftCard, couponError } = this.state;
        const disableIncrement = this.state.ticketNumber >= this.props.seatsAvailable ? true : false;
        const classes = this.props.seatsError ? "quantity-field seat-error" : "quantity-field"
        return (
            <div className="col-md-12 containerPadding">
                <div className="row">
                    <div className="col-md-12">
                        {this.state.giftCard && this.props.location.pathname !== `/giftcard-reservation/${params.giftcode}` ? <div className="input-group formMain">
                            <label className="customLabel textSize">Enter gift coupon code</label>
                            <div className="buttonOut">
                                <input
                                    value={this.state.couponCode}
                                    onChange={(e) => this.setState({ couponCode: e.target.value, couponError: '' })}
                                    type="text"
                                    className="form-control customInputField"
                                    placeholder="Enter code"
                                />
                                <button onClick={() => this.handleCheckCouponCode()}
                                    className={`checkAvailability`}>
                                    Apply Coupon
                                </button>
                            </div>
                            <span className="text-danger error-validation textSize">{couponError}</span>
                        </div> : null}



                        {(!this.state.giftCard || this.props.location.pathname === `/giftcard-reservation/${params.giftcode}`) ? <span className="customHeading">NUMBER OF JUMPERS </span> : null}

                        {(!this.state.giftCard || this.props.location.pathname === `/giftcard-reservation/${params.giftcode}`) ? <div className="input-group">
                            <input type="button" value="-" className="button-minus" data-field="quantity" disabled={this.state.ticketNumber > 0 ? false : true} onClick={(e) => { this.decrementValue(e) }} />
                            <input type="number" step="1" max="" value="1" name="quantity" onChange={this.ChangeTicketNumber.bind(this)}
                                value={this.props.ticketNumber}
                                onFocus={() => this.state.ticketNumber === 0 && this.setState({ ticketNumber: "" })}
                                onBlur={() => {
                                    {
                                        this.validateTicketNumbers();
                                        this.state.ticketNumber === "" && this.setState({ ticketNumber: 0 })
                                    }
                                }}
                                type="text"
                                disabled={giftCard}
                                className={classes}
                                id="ticketNumber"
                                placeholder="Enter a number" />
                            <input type="button" disabled={disableIncrement} value="+" className="button-plus" data-field="quantity" onClick={(e) => { this.incrementValue(e) }} />
                        </div> : null}
                        {disableIncrement&&window.ENV.dzNumber? `You've exceeded the number of slots available online. We may have space for your group. Please call us at ${window.ENV.dzNumber}.`:null}
                        
                    </div>


                    <div className="radio-btns" style={{ marginTop: 20 }}>
                        {this.props.type !== "groupon" && this.props.type !== "gift" ?
                            <React.Fragment>

                                <input type="radio" id="deposit" name="deposit" onChange={() => this.handleRadioButton('deposit')} style={{ marginRight: '10px' }} checked={this.props.payType === "deposit"} />
                                <span>
                                    {window.ENV.useBookingFeeText ? "Pay Booking Fee " : "Pay Deposit "}
                                    {window.ENV.useBookingFeeText ?
                                        <b>- Pay ${this.props.tandemEventDepositAmount}  booking fee towards skydive</b> :
                                        <b>- Pay ${this.props.tandemEventDepositAmount}  deposit towards skydive</b>
                                    }
                                </span>
                                <br />

                                {!this.props.depositOnly &&
                                    <>
                                        <input type="radio" id="deposit" name="deposit" style={{ marginRight: '10px' }} onChange={() => this.handleRadioButton('full')} checked={this.props.payType === "full"} />
                                        <span>Pay In Full</span>
                                        <br /></>}





                                {!window.ENV.disableGiftcardBooking && <><input type="radio" id="deposit"
                                    name="deposit" onChange={() => this.handleRadioButton('giftCard')}
                                    style={{ marginRight: '10px' }}
                                    checked={this.state.useGiftCard}
                                />
                                <span>
                                    Use Giftcard Code &nbsp;
                                </span></>}

                                {this.state.isPhone ?
                                    <div>
                                        <input type="radio" id="deposit"
                                            name="deposit" onChange={() => this.handleRadioButton('nopay')}
                                            style={{ marginRight: '10px' }}
                                            checked={this.props.payType == 'nopay'}
                                        />
                                        <span>
                                            No Payment &nbsp;
                                        </span>
                                        {this.props.payType === "nopay" ?
                                            <div class="input-group formMain">
                                                <label className="customLabel textSize">Add Memo</label>
                                                <div className="buttonOut">
                                                    <input
                                                        value={this.state.memo}
                                                        type="text"
                                                        onChange={this.handleMemoChange}
                                                        className="form-control customInputField"
                                                        placeholder="Enter text"
                                                    />
                                                    <button
                                                        onClick={this.submitMemo}
                                                        className={'checkAvailability'}>
                                                        Add Memo
                                                    </button>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    : null}
                            </React.Fragment>
                            :
                            null
                        }
                    </div>
                </div>
                <Hr />
            </div>
        );
    }
}

export default withRouter(TicketForm);