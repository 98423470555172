import React, { useState,useEffect,useRef, useCallback } from 'react'
import Api from '../../services/api';
import Spinner from './images/loading.svg';
import Valid from './images/valid.svg';
import Invalid from './images/invalid.svg';
import './discount.css';

const DiscountInput = ({
  onChange = () => {},
  handleGiftCodeInvalid,
  handleHaveGiftcardCode,
  	text,
	type,
	isCleared,
	setClearGroupon,
	_grouponCode,
	giftCode,
	eventType,
	ticketCount,
	finalForm,
	validateEmail,
	pathname
}) => {
	const [isValid, setIsValid] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [value, setValue] = useState('');
	const [disable, setDisable] = useState(false)
	// const inputField=useRef('')
	
	useEffect(() => {
		if (giftCode) {
			setValue(giftCode);
			checkGiftcode(giftCode);
		}
		if (isCleared) {
			setValue('');
			setIsValid(false);
			setIsChecked(false);
			setLoading(false);
			setError(null);
			setClearGroupon();
		}
	}, [isCleared])

	useEffect(() => {
		if(_grouponCode) {
			setValue(_grouponCode);
			verifyGrouponCode(_grouponCode);	
			setDisable(true);
		}
	}, [_grouponCode])
	
	const checkGiftcode = (value) => {
	
		if (value.length > 0) {
			Api.verifyGiftcard(value).then(r => {
				if (r.data !== null && r.data.item !== undefined) {
					setIsValid(true);
					onChange(r.data, value);
					console.log("")
					handleGiftCodeInvalid(true)
				} else {
					setIsValid(false);
					onChange({});
					handleGiftCodeInvalid(false)
				}
				setIsChecked(true);
				setLoading(false);
			}).catch(err => {
				setError(err);
				setLoading(false);
				setIsChecked(false);
			});
		}
	}
	const handleOnChange = (e) => {
		const { value } = e.target;

		
		if (!value.trim()) {
			// value is empty
			if(type !== "groupon") onChange(undefined, 0);
			if(type === "groupon") onChange({},"noValid");
			setValue(value);
			return setIsChecked(false);
		}
		setLoading(true);
		if (type === "discount") {
			if (eventType === "Tandem" && pathname !== "/groupon" && pathname !== "/rush49") {
				Api.verifyTandemDiscount(value).then(r => {
					if (r.data.foundId) {
						setIsValid(true);
						onChange(r.data.foundId, 0, value, r.data.discountedTandem);
						// onChange(undefined, 0, value, r.data.discountedTandem);
					} else {
						setIsValid(false);
						onChange(undefined, 0);
					}
					setIsChecked(true);
					setLoading(false);
				}).catch(err => {
					setError(err);
					setLoading(false);
					setIsChecked(false);
				});
			 }
			else {
				Api.verifyDiscount(value).then(r => {
					if (r.data.foundId) {
						setIsValid(true);
						onChange(r.data.foundId, r.data.value, value);
					} else {
						setIsValid(false);
						onChange(undefined, 0);
					}
					setIsChecked(true);
					setLoading(false);
				}).catch(err => {
					setError(err);
					setLoading(false);
					setIsChecked(false);
				});
			}
		}
		else if(type === "groupon"){
			if(value.length>0){
				verifyGrouponCode(value);
			}
		}
		else if (type === "gift") {
			checkGiftcode(value);
		}	
		setValue(value);
	}

	const verifyGrouponCode = (value) => {
		Api.verifyGroupon(value).then(r => {
			if (r.data.packageName !== undefined) {
				setIsValid(true);		        
				onChange(r.data, value);
			} else {
				setIsValid(false);
				onChange({});
			}
			setIsChecked(true);
			setLoading(false);
			}).catch(err => {
			setError(err);
			setLoading(false);
			setIsChecked(false);
			});
	}
	
	const checkForDisable = () => {
	
		let count = pathname === '/phone' ? 1 : ticketCount
		var passed = 0;
		for (let i = 0; i < count; i++) {
			var fdata = finalForm.passengers[i];

			if (fdata && fdata.firstname && fdata.lastname &&
				(i == 0 && fdata.email && validateEmail(fdata.email) || i > 0)
				&&
				(i == 0 && fdata.phone || i > 0)
				&& 
				fdata.adult &&
				fdata.weight &&
				(fdata.tandemPackage != "none" || ticketCount > 0)
				&& fdata.errors.firstname === ""
				&& fdata.errors.lastname === ""
				&& (i == 0 && fdata.errors.email === "" || ticketCount > 0)
				&& (i == 0 ? fdata.errors.phone === "" ? true : false : ticketCount > 0)
			) {
				passed = passed + 1;
				if (passed == count) setDisable(false);
			}
			else {
				setDisable(true)
			}
		}
	}

	useEffect(()=>{
		if(!value && type==="gift" ){
			handleHaveGiftcardCode(false)
		}else if(type==="gift"){
			handleHaveGiftcardCode(true)
		}
	},[value])

	useEffect(() => {
		checkForDisable()
	}, [
		disable
	])
	return (
		<div className="discountInputContainer">
      	<div className="discountInput">
				<input
					style={{ cursor: disable ? "not-allowed" : "text"}}
					type="text"
					onMouseOver={() => checkForDisable()}
					disabled={disable}
					value={value}
					name="discount"
					onChange={handleOnChange}
					placeholder={text}
				/>
        {loading ? <img src={Spinner} /> : null }
        {isValid && isChecked ? <img src={Valid} /> : null }
        {!isValid && isChecked ? <img src={Invalid} /> : null }
      </div>
      {error && <div className="discountInputError">{error}</div>}
    </div>
  )
}


export default DiscountInput